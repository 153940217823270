<template>
  <div class="modal-backdrop" @mousedown.self="closeModal">
    <div class="close-btn" @click="closeModal">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M4 4L12 12M20 20L12 12M12 12L20 4M12 12L4 20"
                  stroke="currentColor"
                  stroke-linecap="round"
                />
              </svg>
    </div>
    <div class="video-ei-wrapper" id="modal-video">
      <iframe
        :src="`${videoUrl}`"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>

<script>
import { onMounted } from "@vue/runtime-core";
export default {
  name: "video-ei",
  emits: ["closeVideoModal"],

  props: {
    videoUrl: {
      type: String,
      default: "-KtupbqCiws",
    },
  },

  setup(props, context) {
    let closeModal = () => {
      document.getElementsByTagName("html")[0].style.overflow = "auto";
      context.emit("closeVideoModal");
    };

    onMounted(() => {
      document.getElementsByTagName("html")[0].style.overflow = "hidden";
    });

    return {
      closeModal,
    };
  },
};
</script>


<style lang="scss" scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  overflow-y: auto;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1025px) {
    padding: 15px 0;
  }
}

.close-btn {
  position: absolute;
  display: flex;
  top: 3%;
  right: 3%;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 4px;
  color: white;
  cursor: pointer;
}

.video-ei-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 4;

  iframe {
    width: 1200px;
    height: 600px;
  }

  @media (max-width: 1200px) {
    iframe {
      width: 600px;
      height: 400px;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    iframe {
      width: 100%;
    }
  }

  @media (max-width: 425px) {
    iframe {
      width: 90%;
      height: 200px;
    }
  }
}
</style>