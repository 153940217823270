<template>
  <div class="team-layout">
    <section class="team-section s-team--intro">
      <div class="container">
        <h1>Команда W<span class="color-accent-l">2</span>W</h1>
        <p>
          Наша миссия – помочь компаниям использовать эмоции как топливо для
          непрерывного движения вперед.
        </p>
        <img src="@/assets/images/all_team.webp" alt="" />
        <p>
          Мы — команда ученых и ИТ-специалистов, создаем высокотехнологичные решения в области эмоционально- интеллектуального развития бизнеса.

          Наш научный руководитель:

          д.психол.н. Е.А. Сергиенко – профессор, Институт психологии РАН.

          Сотрудничаем с мировыми экспертами из Yale University, University of Cambridge, Российской Академии Наук (РАН) и другими ведущими международными организациями.
        </p>
        <p>
          Согласно международным исследованиям, эмоциональный интеллект помогает
          человеку и команде раскрыть свой собственный потенциал, увеличивая
          эффективность, преодолевая стресс, минимизируя риск выгораний.
        </p>
        <p>
          Используя системный подход, мы предлагаем точечные и комплексные
          решения для корпораций, а также работаем с индивидуальными запросами.
        </p>
        <p>
          Это делает нас ведущими специалистами в области эмоционального
          интеллекта, которые предлагают высокотехнологичные решения в его
          оценке и развитии.
        </p>
      </div>
    </section>

    <section v-if="firstStaff && secondStaff" class="team-section s-team--head">
      <div class="container">
        <div class="s-title">Наша команда</div>
        <div class="s-team--lg-grid display--grid grid-2">
          <div class="__item">
            <div class="__image">
              <img :src="`${firstStaff.full_image}`" alt="" />
              <button
                @click="
                  () => {
                    openVideos.push(firstStaff.id);
                  }
                "
                v-if="firstStaff.youtube_id"
                href="#"
                class="__video"
              >
                <img src="@/assets/images/video-btn.png" alt="" />
                <p>Видеообращение <br />Елены Хлевной</p>
              </button>
            </div>
            <div class="__title">{{ firstStaff.name }}</div>
            <div class="__job">{{ firstStaff.post }}</div>
            <div class="__text">
              <p v-html="firstStaff.full_description">
              </p>
            </div>
          </div>
          <div v-if="secondStaff" class="__item">
            <div class="__image">
              <img :src="`${secondStaff.full_image}`" alt="" />
              <button
                @click="
                  () => {
                    openVideos.push(secondStaff.id);
                  }
                "
                v-if="secondStaff.youtube_id"
                href="#"
                class="__video"
              >
                <img src="@/assets/images/video-btn.png" alt="" />
                <p>Видеообращение <br />Олега Елманова</p>
              </button>
            </div>
            <div class="__title">{{ secondStaff.name }}</div>
            <div class="__job">
              {{ secondStaff.post }}
            </div>
            <div class="__text">
              <p v-html="secondStaff.full_description">
              </p>
            </div>
          </div>
        </div>
        <video-ei
          @closeVideoModal="closeVideo(firstStaff.id)"
          v-if="checkOpenVideos(firstStaff.id)"
          :videoUrl="firstStaff.youtube_id"
        ></video-ei>
        <video-ei
          @closeVideoModal="closeVideo(secondStaff.id)"
          v-if="checkOpenVideos(secondStaff.id)"
          :videoUrl="secondStaff.youtube_id"
        ></video-ei>
      </div>
    </section>

    <section v-if="currentStaff.length" class="team-section s-team--slider">
      <div class="container">
        <div class="__slider">
          <swiper
            slidesPerView="auto"
            :spaceBetween="20"
            :loop="true"
            :pagination="{
              el: '.team-pagination',
              clickable: true,
            }"
            :navigation="{
              nextEl: '.team-button-next',
              prevEl: '.team-button-prev',
            }"
            :breakpoints="teamBreakpoints"
          >
            <swiper-slide v-for="st of staff" :key="st.id" class="team-sm">
              <img :src="`${st.full_image}`" alt="" />
              <button
                @click="
                  () => {
                    openVideos.push(st.id);
                  }
                "
                v-if="st.youtube_id"
                href="#"
                class="__video"
              >
                <img src="@/assets/images/video-btn.png" alt="" />
              </button>
              <div class="__title">{{ st.name }}</div>
              <div class="__text" v-html="st.description">
              </div>
            </swiper-slide>

            <div class="swiper-pagination team-pagination"></div>
            <div class="swiper-button-prev team-button-prev"></div>
            <div class="swiper-button-next team-button-next"></div>
          </swiper>
        </div>
      </div>
    </section>
    <div v-for="(st, index) of staff" :key="index">
      <video-ei
        @closeVideoModal="closeVideo(st.id)"
        v-if="checkOpenVideos(st.id)"
        :videoUrl="st.youtube_id"
      ></video-ei>
    </div>
    <section class="team-section s-team--video">
      <div class="container">
        <div
          class="s-product--about-video display--flex bg-light"
          style="
            background-image: url(https://img.youtube.com/vi/syNy15Z5i5o/maxresdefault.jpg);
          "
        >
          <button
            @click="openBigVideo = true"
            type="button"
            class="btn btn-accent btn-ico"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="12" cy="12" r="12" fill="white" />
              <path d="M16 12.5L10 17L10 8L16 12.5Z" fill="#01AF08" />
            </svg>
            Смотреть видео
          </button>
        </div>
      </div>
    </section>
    <video-ei
      v-if="openBigVideo"
      @closeVideoModal="openBigVideo = false"
      videoUrl="https://lms.way2wei.ru/upload/disk/4cb/4cbf650e48edcc4dca1a926953216f71/58293d6b8df0344fe7c09b1b946cf7fa"
    />

    <section class="team-section s-team--complex slider-lg">
      <div class="container">
        <slider-lg
          title="Наш вклад в развитие эмоционального интеллекта в России и мире"
          name="contribution"
        >
          <swiper-slide v-for="data in contributionData" :key="data.id">
            <img :src="data.img" alt="" />
            <div class="__text">
              <p>{{ data.text }}</p>
            </div>
          </swiper-slide>
        </slider-lg>
      </div>
    </section>

    <section class="team-section s-team--complex slider-lg">
      <div class="container">
        <slider-lg
          title="Ежегодная международная конференция от WAY2WEI"
          name="conference"
        >
          <swiper-slide>
            <img src="@/assets/images/about/conference1.webp" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img src="@/assets/images/about/conference2.webp" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img src="@/assets/images/about/conference3.webp" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img src="@/assets/images/about/conference4.webp" alt="" />
          </swiper-slide>
        </slider-lg>
        <div class="__text">
          <p>
            С 2017 года совместно с российскими и зарубежными коллегами мы
            организуем ежегодную международную конференцию по эмоциональному
            интеллекту. За время проведения конференция мы опробовали более 10
            форматов выступлений HR-специалистов крупнейших российских компаний,
            а также международных экспертов в области эмоционального интеллекта.
            Всего в конференции приняли участие более 3000 слушателей.
          </p>
          <p>
            Наша цель – создать коммьюнити людей, которые хотят качественно
            улучшить жизнь компании при помощи главного инструмента XXI века –
            ЭМОЦИОНАЛЬНОГО ИНТЕЛЛЕКТА.
          </p>
          <p>
            Мы гордимся, что получили право от международного научного
            сообщества по эмоциональному интеллекту проведения 9-го
            Международного конгресса по ЭИ в 2023 году в России. До этого
            конгресс проводился в Испании (2007, 2009 гг.), Хорватии (2011), США
            (2013), Аргентине (2015), Португалии (2017), в Австралии (2019).
          </p>
        </div>
      </div>
    </section>

    <section class="team-section s-team--materials">
      <div class="container">
        <div class="s-title">Наши достижения</div>
        <div class="s-material--grid display--grid grid-2">
          <div class="__col">
            <div class="__item">
              <div class="__title">Статьи</div>
              <div class="__text">
                <p>
                  <a href="https://doi.org/10.4324/9781003130796"
                    >1. Sergienko E.A., Khlevnaya E.A., Migun J.P., Osipenko
                    E.I. Russian-language method "Emotional Intelligence Test"
                    (EIT)" // Eastern European Perspectives on Emotional
                    Intelligence. Current Developments and Research / Edited By
                    Lada Kaliská, John Pellitteri. London, Routledge, 2021. С.
                    19 – 55.</a
                  >
                </p>
                <p>
                  2. Сергиенко Е.А., Хлевная Е.А., Киселева Т.С., Никитина А.А.,
                  Осипенко Е.И. Роль эмоционального интеллекта в психологическом
                  благополучии человека и в совладании со стрессом // Вопросы
                  психологии. № 1. 2021. С. 71 – 81.
                </p>
                <p>
                  <a href="https://elibrary.ru/item.asp?id=44656372"
                    >3. Сергиенко Е.А., Хлевная Е.А., Киселева Т.С., Никитина
                    А.А., Осипенко Е.И. Роль эмоционального интеллекта в
                    совладании со сложными жизненными ситуациями // Вестник
                    Костромского государственного университета. Серия:
                    Педагогика. Психология. Социокинетика. 2020. Т. 26. №. 4. С.
                    120-128. DOI
                    https://doi.org/10.34216/2073-1426-2020-26-4-120-128</a
                  >
                </p>
              </div>
              <div v-if="showAllArticles" class="__text">
                <p>
                  <a
                    href="https://kp-journal.ru/роль-эмоционального-интеллекта-в-про"
                    >4. Хлевная Е.А., Киселева Т.С., Сергиенко Е.А., Никитина
                    А.А., Осипенко Е.И. Роль эмоционального интеллекта в
                    профессиональном благополучии человека // Казанский
                    педагогический журнал. – 2020. – №. 6 (143). DOI:
                    10.51379/KPJ.2020.74.86.036
                  </a>
                </p>
                <p>
                  <a href="https://elibrary.ru/item.asp?id=42728250"
                    >5. Сергиенко Е.А., Хлевная Е.А., Киселёва Т.С. Роль
                    эмоционального интеллекта в эффективности деятельности и
                    психологическом благополучии человека // Вестник
                    Костромского Государственного Университета. Серия:
                    Педагогика. Психология. Социокинетика. 2020. Т. 26. С.
                    46-53. DOI 10.34216/2073-1426-2020-26-1-46-53
                  </a>
                </p>
                <p>
                  <a
                    href="https://www.national-zdorov.ru/m/products/Psychological%20sciences/gid657/"
                    >6. Хлевная Е.А., Киселева Т.С., Сергиенко Е.А., Никитина
                    А.А., Осипенко Е.И. Профессиональная и региональная
                    специфика эмоционального интеллекта // Национальное
                    здоровье. 2020. № 4. С. 117-124.</a
                  >
                </p>
                <p>
                  <a href="https://elibrary.ru/item.asp?id=44327552"
                    >7. Сергиенко Е.А., Хлевная Е.А., Киселева Т.С. Тест
                    эмоционального интеллекта Дж. Мэйера, П. Сэловея, Д. Карузо
                    в подростковой исследовательской версии: Русскоязычная
                    версия (MSCEIT-YRV™): Руководство исследователя. – М.:
                    Институт психологии РАН, 2020. – 108 c.
                  </a>
                </p>
                <p>
                  <a href="https://elibrary.ru/item.asp?id=42801866"
                    >8. Sergienko E.A, Khlevnaya E.A, Osipenko E.I. Development
                    of an objective methodology for measurement of emotional
                    intelligence. Emotional intelligence and socio-demographics
                    of employees in russian organizations // ОРГАНИЗАЦИОННАЯ
                    ПСИХОЛОГИЯ. 2020. Т. 10. № 1. С. 45-61.</a
                  >
                </p>
                <p>
                  <a href="https://www.elibrary.ru/item.asp?id=41655351"
                    >9. Сергиенко Е.А., Хлевная Е.А., Киселева Т.С., Мигун Ю.П.
                    Развитие эмоционально-интеллектуальных навыков дошкольников,
                    младших школьников, младших и старших подростков во
                    внешкольных центрах. Учебно-методическое пособие:
                    теоретическая часть. Сер. Методы психологии. – М.: Институт
                    психологии РАН, 2019. – 136 с.
                  </a>
                </p>
                <p>
                  <a href="https://www.elibrary.ru/item.asp?id=41655368"
                    >10. Сергиенко Е.А., Хлевная Е.А., Киселева Т.С., Мигун Ю.П.
                    Развитие эмоционально-интеллектуальных навыков дошкольников,
                    младших школьников, младших и старших подростков во
                    внешкольных центрах. Учебно-методическое пособие:
                    практическая часть. Сер. Методы психологии. – М.: Институт
                    психологии РАН, 2019. – 188 с.</a
                  >
                </p>
                <p>
                  11. Сергиенко Е.А., Хлевная Е.А., Киселёва Т.С., Осипенко
                  Е.И., Никитина А.А., Мигун Ю.П. Эмоциональный интеллект: от
                  истоков к перспективам. Монография – М., 2019. – 254 с. ISBN
                </p>
              </div>
              <div class="btn-action display--flex">
                <button
                  v-if="!showAllArticles"
                  @click="showAllArticles = true"
                  type="button"
                  class="btn btn-o btn-sm"
                >
                  Показать все
                </button>
                <button
                  v-if="showAllArticles"
                  @click="showAllArticles = false"
                  type="button"
                  class="btn btn-o btn-sm"
                >
                  Скрыть
                </button>
              </div>
            </div>
          </div>
          <div class="__col">
            <div class="__item">
              <div class="__title">Наши книги</div>
              <div class="__text">
                <p>
                  <a href="https://www.labirint.ru/books/563669/"
                  >Карузо Д., Сэловей П. «Эмоциональный интеллект
                    руководителя. Как развивать и применять»</a
                  >
                </p>
                <p>
                  <a
                    href="https://www.litres.ru/elena-hlevnaya/emocionalnyy-intellekt-v-biznese-reshenie-slozhnyh-51651446/"
                    >Хлевная Е.А., Карузо Д., Рис Л. «Эмоциональный интеллект в
                    бизнесе. Решение сложныхлидерских задач»</a
                  >
                </p>
                <p>
                  <a
                    href="https://www.litres.ru/elena-hlevnaya/gde-tvoya-volshebnaya-knopka-kak-razvivat-emocionalnyy-intellekt/"
                    >Хлевная Е., Южанинова Л. «Где твоя волшебная кнопка? Как
                    развивать эмоциональный интеллект»</a
                  >
                </p>
                <p>
                  <a href="https://www.labirint.ru/books/343591/"
                    >Хлевная Е.А. «Джинн, который всегда с тобой. Эмоциональный
                    интеллект в бизнесе»</a
                  >
                </p>
                <p>
                  <a href="https://www.labirint.ru/books/827262/"
                    >Киселева Т.С., Сергиенко Е.А., Хлевная Е.А. «Азбука
                    эмоций»</a
                  >
                </p>
                <p>
                  <a href="https://www.labirint.ru/books/779603/"
                    >Сергиенко Е.А., Хлевная Е.А., Киселева Т.С. «Эмоциональный
                    интеллект ребенка и здравый смысл его родителей»</a
                  >
                </p>
              </div>
            </div>
            <div class="__item">
              <div class="__title">Патенты</div>
              <div class="__text">
                <p>
                  Право ООО «Лаборатории эмоционального интеллекта» на
                  интеллектуальную собственность по тематике проекта
                  подтверждено:
                </p>
                <p>
                  <a
                    href="https://www1.fips.ru/registers-doc-view/fips_servlet?DB=EVM&DocNumber=2018618517&TypeFile=html"
                    >1. Программа для ЭВМ «Тест Эмоционального Интеллекта» -
                    Регистрационный номер в ФИПС: 2018618517.</a
                  >
                </p>
                <p>
                  <a
                    href="https://www1.fips.ru/registers-doc-view/fips_servlet?DB=DB&DocNumber=2019620883&TypeFile=html"
                    >2. База данных «Тест Эмоционального Интеллекта» -
                    Регистрационный номер в ФИПС: 2019620883.</a
                  >
                </p>
                <p>
                  <a
                    href="https://www1.fips.ru/registers-doc-view/fips_servlet?DB=RUPAT&DocNumber=2720400&TypeFile=html"
                    >3. Способ повышения эффективности человека на основе оценки
                    и развития эмоционального интеллекта - Регистрационный номер
                    в ФИПС: 2019145107.</a
                  >
                </p>
                <p>
                  4. Товарный знак «Лаборатория ЭИ» - Регистрационный номер в
                  ФИПС: 2018706165.
                </p>
              </div>
              <div v-if="showAll" class="__text">
                <p>
                  5. Товарный знак, словесный знак обслуживания «ТЭИ Тест
                  Эмоционального Интеллекта» - Регистрационный номер в ФИПС:
                  744133
                </p>
                <p>
                  6. Товарный знак, словесный знак обслуживания «РЭИ» -
                  Регистрационный номер в ФИПС: 756457
                </p>
                <p>
                  7. Товарный знак, знак обслуживания «Работающий Эмоциональный
                  Интеллект» - Регистрационный номер в ФИПС: 644415
                </p>
                <p>
                  8. Товарный знак, знак обслуживания «ЛАБОРАТОРИЯ
                  ЭМОЦИОНАЛЬНОГО ИНТЕЛЛЕКТА» - Регистрационный номер в ФИПС:
                  630782
                </p>
                <p>
                  9. Товарный знак, знак обслуживания «WorkingEI» -
                  Регистрационный номер в ФИПС: 741671
                </p>
                <p>
                  10. Товарный знак, словесный знак обслуживания «WEI Working
                  Emotional Intelligence» - Регистрационный номер в ФИПС: 740852
                </p>
                <p>
                  11. Товарный знак, словесный знак обслуживания «EIT Emotional
                  intelligence test» - Регистрационный номер в ФИПС: 743834
                </p>
              </div>
              <div class="btn-action display--flex">
                <button
                  v-if="!showAll"
                  @click="showAll = true"
                  type="button"
                  class="btn btn-o btn-sm"
                >
                  Показать все
                </button>
                <button
                  v-if="showAll"
                  @click="showAll = false"
                  type="button"
                  class="btn btn-o btn-sm"
                >
                  Скрыть
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import Sw from "swiper";

Sw.use([Navigation, Pagination]);

import "swiper/swiper-bundle.min.css";
import SliderLg from "../../components/sliders/slider-lg/SliderLg.vue";
import { useHead } from '@vueuse/head'
import { useStore } from "vuex";
import { computed, onBeforeMount, ref } from "@vue/runtime-core";
import VideoEi from "../../components/video-ei/VideoEi.vue";

export default {
  name: "about-command",
  inject: ["backUrl"],
  components: {
    Swiper,
    SwiperSlide,
    SliderLg,
    VideoEi,
  },
  setup() {
    const store = useStore();

    let staff = computed(() => store.state.staff);

    let firstStaff = ref(null),
      secondStaff = ref(null),
      currentStaff = ref([]),
      openVideos = ref([]),
      openBigVideo = ref(false),
      showAll = ref(false),
      showAllArticles = ref(false);
    useHead({
      title:
        "Команда WAY2WEI: Ученые и IT-специалисты | Сколково",
      meta: [
        {
          property: "description",
          content:
            "Создаем цифровые инструменты оценки и развития эмоционального интеллекта. 20 патентов РФ, резиденты IT-кластера Сколково, входим в реестр отечественного ПО",
        },
        {
          property: "og:keywords",
          content:
            "эмоциональный интеллект книга, эмоциональный интеллект статья, эмоциональный интеллект наука, конференция эмоциональный интеллект, эмоциональный интеллект +в россии, eilab, Лаборатория эмоционального интеллекта  ",
        },
      ],
    });

    let contributionData = [
      {
        id: 1,
        text: "Мы являемся эксклюзивными представителями в России и СНГ ДЭВИДА КАРУЗО – со-основателя концепции эмоционального интеллекта как модели способности, а также профессора университета Швейцарии ДАВИДЕ АНТОНЬЯЦЦА, профессора университета Палермо АНТОНЕЛЛЫ ДЕ АМИКО и других международных экспертов в области эмоционального интеллекта. Совместными усилиями мы разрабатываем практические инструменты для бизнеса. ",
        img: require("@/assets/images/about/contribution1.webp"),
      },
      {
        id: 2,
        text: "С 2020 года мы являемся участниками Инновационного центра “Сколково”. Наш офис базируется в Технопарке “Сколково”. Благодаря этому мы можем постоянно совершенствовать платформу, оснащая ее новыми высокотехнологичными продуктами.",
        img: require("@/assets/images/about/contribution2.webp"),
      },
      {
        id: 3,
        text: "Под руководством научного руководителя нашего проекта, доктора психологических наук, профессор ИП РАН, ЕЛЕНЫ СЕРГИЕНКО, мы проводим исследования эмоционального интеллекта. Совместными усилиями мы подготовили более 30 научных статей, написали 3 монографии, выпустили 2 книги и т.д.",
        img: require("@/assets/images/about/contribution3.webp"),
      },
      {
        id: 4,
        text: "Наша команда регулярно выступает на международных конференциях, представляя исследования в области эмоционального интеллекта и результаты его практического применения от лица российского научного сообщества.",
        img: require("@/assets/images/about/contribution4.webp"),
      },
    ];

    let checkOpenVideos = (id) => openVideos.value.find((video) => id == video);
    let closeVideo = (id) => {
      openVideos.value.splice(openVideos.value.indexOf(id), 1);
    };

    onBeforeMount(() => {
      store.dispatch("getStaff").then(() => {
        currentStaff.value = staff.value;
        firstStaff.value = currentStaff.value.splice(
          currentStaff.value.length - 1,
          1
        )[0];
        secondStaff.value = currentStaff.value.splice(
          currentStaff.value.length - 1,
          1
        )[0];
      });
    });

    const teamBreakpoints = {
      400: {
        slidesPerView: 2,
      },
      665: {
        spaceBetween: 30,
        slidesPerView: 3,
      },
      1024: {
        slidesPerView: 4,
      },
    };

    return {
      teamBreakpoints,
      staff,
      firstStaff,
      secondStaff,
      currentStaff,
      openVideos,
      checkOpenVideos,
      closeVideo,
      openBigVideo,
      contributionData,
      showAll,
      showAllArticles,
    };
  },
};
</script>

<style scoped>
.team-section,
.method-section {
  padding: 60px 0;
}

.team-section + .team-section,
.method-section + .method-section,
.team-section.s-team--video {
  padding-top: 0;
}

.s-team--intro {
  padding-top: 170px;
}

.s-team--intro h1 {
  margin: 0 0 35px 0;
  color: var(--color);
  font-size: 62px;
  font-weight: 300;
  line-height: 62px;
}

.s-team--intro img {
  display: block;
  width: 100%;
  max-width: 100%;
  height: 620px;
  margin: 0 0 35px 0;
  border-radius: 10px;
  object-fit: cover;
}

.s-team--intro p {
  margin: 0 0 20px 0;
  font-size: 28px;
  line-height: 34px;
  text-align: left;
}

.s-team--lg-grid .__image {
  position: relative;
}

.s-team--lg-grid .__image > img {
  display: block;
  width: 350px;
  max-width: 100%;
  height: 460px;
  margin: 0 0 20px 0;
  border-radius: 10px;
  object-fit: cover;
}

.s-team--lg-grid .__video,
.s-team--slider .__video {
  position: absolute;
  bottom: 0;
  left: 365px;
  color: var(--accent);
  text-decoration: none;
  z-index: 2;
  background: transparent;
  border: none;
  font-weight: 100;
  cursor: pointer;
}

.s-team--slider .__video {
  right: -45px;
  top: 150px;
  bottom: unset;
  left: unset;
}

.s-team--lg-grid .__video img {
  position: absolute;
  bottom: calc(100% - 15px);
  left: -50%;
  cursor: pointer;
}

.s-team--lg-grid .__video p {
  margin: 0;
  font-size: 16px;
  line-height: 1em;
  text-align: left;
}

.s-team--lg-grid .__video:hover {
  color: var(--accent-l);
}

.s-team--lg-grid .__title {
  font-size: 28px;
  line-height: 34px;
}

.s-team--lg-grid .__job {
  font-size: 16px;
  line-height: 20px;
}

.s-team--lg-grid .__text p {
  margin: 20px 0 0 0;
  font-size: 18px;
  line-height: 22px;
  word-break: break-word;
}

.team-sm > img {
  display: block;
  width: 100%;
  max-width: 100%;
  height: 320px;
  margin: 0 0 10px 0;
  border-radius: 4px;
  object-fit: cover;
}

.team-sm .__title {
  font-size: 24px;
  line-height: 28px;
}

.team-sm .__text p {
  margin: 12px 0 0 0;
  font-size: 18px;
  line-height: 22px;
}

.s-team--video .s-product--about-video {
  height: 650px !important;
  align-items: center !important;
  justify-content: center !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  border-radius: 10px !important;
}

.s-team--complex .__text {
  font-size: 24px;
  line-height: 34px;
  text-align: left;
  margin-top: 20px;
}

.s-team--complex {
  padding-bottom: 0 !important;
}

.s-team--materials .__item + .__item {
  margin-top: 35px;
}

.s-team--materials .__title {
  margin-bottom: 5px;
  font-size: 28px;
  line-height: 34px;
}

.s-team--materials p {
  margin: 0 0 10px 0;
  font-size: 16px;
  line-height: 19px;
}

.s-team--materials .btn-action {
  margin-top: 20px;
}

.team-layout {
  overflow-x: hidden;
}

.s-material--grid a {
  color: var(--color);
}

.s-material--grid a:hover {
  text-decoration: underline;
}

@media (max-width: 1024px) {
  .s-team--lg-grid .__video p {
    display: none;
  }

  .s-team--lg-grid .__video {
    bottom: 45px;
    left: 290px;
  }

  .s-team--intro p {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .s-team--video .s-product--about-video {
    height: 220px !important;
    margin-bottom: 35px;
  }

  .team-section {
    padding: 35px 0;
  }

  .s-team--intro {
    padding-top: 120px;
  }

  .s-team--intro h1 {
    font-size: 46px;
    line-height: 51px;
  }

  .s-team--intro img {
    height: 175px;
  }

  .s-team--lg-grid .__image > img {
    width: 230px;
    height: 300px;
  }

  .s-team--lg-grid .__video {
    top: 100px;
    left: 175px;
  }

  .s-team--lg-grid .__video img {
    position: relative;
    top: 0;
    bottom: auto;
    left: 0;
  }

  .s-team--complex .__text {
    font-size: 18px;
    line-height: 22px;
  }
}

@media (max-width: 425px) {
  .s-team--slider .__video {
    display: none;
  }
}
</style>
